<script setup>
import { useCssModule } from 'vue';

const props = defineProps({
  // Replace default classes with custom classes. This is useful if parent component needs to use
  // classes that conflict with the default classes.
  customClasses: {
    type: [String, Array],
    default: null,
  },
  extraSmall: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: [Array, String],
    default: '',
  },
  iconPosition: {
    type: String,
    default: 'left',
    validator(value) {
      return ['left', 'right'].includes(value);
    },
  },
  text: {
    type: String,
    default: '',
  },
});

const $style = useCssModule();

const containerClass = computed(() => {
  const defaultClasses = [
    'tw-inline-block',
    'tw-border',
    'tw-rounded',
    'tw-font-bold',
    'tw-px-1.5',
    props.extraSmall ? $style.extraSmall : 'tw-py-0.5 tw-text-xs',
    $style.container,
  ];

  return props.customClasses || defaultClasses;
});

const handleTooltipClick = ($event) => {
  if (props.tooltipOptions) {
    $event.stopImmediatePropagation();
  }
};
</script>

<template>
  <small
    :class="containerClass"
  >
    <font-awesome-icon
      v-if="icon && iconPosition === 'left'"
      class="tw-mr-1"
      :icon="icon"
      @click="handleTooltipClick"
    />
    {{ text }}
    <font-awesome-icon
      v-if="icon && iconPosition === 'right'"
      class="tw-ml-1"
      :icon="icon"
      @click="handleTooltipClick"
    />
  </small>
</template>

<style module>
.container {
  height: fit-content;
}

.extraSmall {
  font-size: 0.75rem;
  line-height: 0.875rem;
}
</style>
