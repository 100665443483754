<template>
  <SiteNavBar
    :class="['lg:tw-sticky lg:tw-top-0 tw-z-30', $style.container]"
  >
    <template #brand>
      <NuxtLink to="/">
        <img
          :alt="config.siteName"
          class="tw-w-48 tw-inline"
          src="~/assets/img/OpenSnowLogo.png"
        >
      </NuxtLink>
      <AllAccessBadge :class-names="allAccessBadgeClassNames" />
    </template>
    <template #menu>
      <div
        v-if="canShowNavContent"
        :class="searchClass"
      >
        <SiteHeaderSearch />
      </div>
      <ul
        v-if="canShowNavContent"
        class="lg:tw-flex"
      >
        <li
          v-for="pageLink in pageLinks"
          :key="pageLink.text"
          :class="[navItemClass, 'lg:tw-mr-4']"
        >
          <Component
            :is="getLinkComponent(pageLink)"
            :class="[navItemAnchorClass, getNavItemAnchorActiveClass(pageLink)]"
            :href="getLinkHref(pageLink)"
            :to="pageLink.to"
            @click="handleCollapseNav"
          >
            <font-awesome-icon
              v-if="pageLink.icon"
              :class="navItemIconClass"
              :icon="pageLink.icon"
            />
            {{ pageLink.text }}
          </Component>
        </li>
      </ul>
      <ul
        v-for="(userLinks, index) in userLinkSets"
        :key="index"
        :class="[
          userLinksClass,
          index === 0 ? 'lg:tw-hidden' : '',
          index === 1 ? 'tw-hidden lg:tw-flex' : '',
        ]"
      >
        <li
          v-for="userLink in userLinks"
          :key="userLink.text"
          :class="[
            navItemClass,
            'lg:tw-ml-4',
          ]"
        >
          <Button
            v-if="userLink.buttonClass"
            :additional-class-names="userLink.buttonClass"
            :display-block="true"
            :to="userLink.to"
            :type="userLink.type"
          >
            {{ userLink.text }}
          </Button>
          <Component
            :is="getLinkComponent(userLink)"
            v-else
            :class="[navItemAnchorClass, getNavItemAnchorActiveClass(userLink)]"
            :href="getLinkHref(userLink)"
            :target="userLink.target"
            :to="userLink.to"
            @click="(event) => handleUserLinkClick(event, userLink)"
          >
            <font-awesome-icon
              v-if="userLink.icon"
              :class="navItemIconClass"
              :icon="userLink.icon"
            />
            {{ userLink.text }}
          </Component>
        </li>
      </ul>
      <DropdownMenu
        additional-dropdown-class="tw-order-4 tw-top-8"
        :card-props="{
          cardBackgroundColor: '',
          cardClassNames: 'brand-color',
          hasBodyPaddingSmall: true,
        }"
        :position="accountDropdownPosition"
        :show-menu="showAccountDropdown"
        style="/* stylelint-disable */ --dropdown-menu-show-menu-min-height: 2rem;"
        @close="handleAccountDropdownClose"
      >
        <template #menu>
          <ul>
            <li
              v-for="dropdownLink in dropdownLinks"
              :key="dropdownLink.text"
              :class="[
                'tw-w-64 first:tw-border-b first:tw-pb-1',
                'text-light-color hover:tw-text-white',
                $style.accountDropdownListItem,
              ]"
            >
              <Component
                :is="getLinkComponent(dropdownLink)"
                class="tw-block tw-py-2 tw-cursor-pointer hover:tw-no-underline"
                :href="getLinkHref(dropdownLink)"
                :to="dropdownLink.to"
                @click="(event) => handleDropdownLinkClick(event, dropdownLink)"
              >
                <font-awesome-icon
                  v-if="dropdownLink.icon"
                  class="tw-inline-block tw-w-6 tw-h-4 tw-mr-1.5 tw-fill-current"
                  :icon="dropdownLink.icon"
                />
                {{ dropdownLink.text }}
              </Component>
            </li>
          </ul>
        </template>
      </DropdownMenu>
    </template>
  </SiteNavBar>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { isScreenLg } from '@@/utils/CommonUtils';
import { addReturnTo, getStartFreeTrialUrl } from '@@/utils/LoginUtils';
import { useLoginStore } from '@@/stores/Login';
import { useUserStore } from '@@/stores/User';
import { useUiStore } from '@@/stores/Ui';
import DefaultTrialMixin from '@@/utils/DefaultTrialMixin';
import SiteHeaderSearch from './SiteHeaderSearch.vue';

/**
 * The site header has slightly different displays for different user types:
 *
 * - Guest User: Help Link no Icon, Log In Link no Icon, Create Free Account Button
 * - Registered Free User: Help Link with Icon, Account Link with Icon, Buy Now Button
 * - Registered All-Access User: Help Link with Icon, Account Link with Icon
 *
 * @todo
 * - Implement logout functionality
 */
export default {
  name: 'SiteHeader',

  components: {
    SiteHeaderSearch,
  },

  mixins: [
    DefaultTrialMixin,
  ],

  setup() {
    const config = useRuntimeConfig().public;
    const showAccountDropdown = ref(false);
    return { config, showAccountDropdown };
  },

  computed: {
    ...mapState(useUserStore, ['canShowAuthContent', 'isAllAccess', 'isGuest']),

    accountDropdownPosition() {
      if (!this.isAllAccess && !this.isGuest) {
        return this.$style.accountDropdownFreeUser;
      }

      return 'right';
    },

    accountLink() {
      return {
        icon: 'user',
        onClick: this.toggleAccountDropdown,
        text: 'Account',
      };
    },

    allAccessBadgeClassNames() {
      return [
        this.isAllAccess
          ? `tw-hidden lg:tw-inline-block ${this.$style.showAllAccess}`
          : 'tw-hidden',
        'tw-align-middle tw-whitespace-nowrap tw-overflow-x-hidden',
        this.$style.allAccessBadge,
      ];
    },

    canShowNavContent() {
      return !this.isGuest;
    },

    dropdownLinks() {
      return [
        this.settingsLink,
        this.logOutLink,
      ];
    },

    helpLink() {
      return {
        href: 'https://support.opensnow.com',
        icon: this.isGuest ? null : 'question',
        target: '_blank',
        text: 'Help',
      };
    },

    logInLink() {
      const query = this.getReturnToQuery();
      const path = '/user/login';
      const text = 'Log In';
      const to = addReturnTo(path, query);

      return { text, to };
    },

    logOutLink() {
      return {
        href: '/user/logout',
        icon: 'arrow-right-from-bracket',
        onClick: this.handleLogout,
        text: 'Log Out',
      };
    },

    navItemAnchorClass() {
      return 'tw-block lg:tw-inline-block tw-py-2 lg:tw-px-2 tw-cursor-pointer hover:tw-no-underline hover:tw-text-white';
    },

    navItemClass() {
      return [
        this.isGuest ? 'lg:tw-block lg:tw-py-2' : '',
        'tw-text-base tw-font-normal',
        this.isGuest ? 'lg:tw-text-sm' : 'lg:tw-text-xs',
        'text-light-color tw-whitespace-nowrap',
      ];
    },

    navItemIconClass() {
      return 'tw-inline-block lg:tw-block tw-w-6 tw-h-4 tw-mr-1.5 lg:tw-mb-1 lg:tw-mx-auto tw-fill-current';
    },

    pageLinks() {
      return [
        {
          icon: ['far', 'location-dot'],
          text: 'My Location',
          to: '/my-location',
        },
        {
          icon: ['far', 'star'],
          text: 'Favorites',
          to: '/user/favorites',
        },
        {
          icon: ['far', 'map'],
          text: 'Maps',
          to: '/map',
        },
        {
          icon: ['fal', 'users'],
          text: 'Experts',
          to: '/dailysnow',
        },
        {
          icon: ['fas', 'search'],
          text: 'Explore',
          to: '/explore',
        },
      ];
    },

    searchClass() {
      return 'tw-relative tw-flex-auto tw-my-4 tw-mx-0 lg:tw-my-0 lg:tw-mr-4 lg:tw-order-2';
    },

    settingsLink() {
      return {
        to: '/user/settings/account',
        icon: 'cog',
        text: 'Settings',
      };
    },

    startFreeTrialButton() {
      const query = this.getReturnToQuery();

      const params = {
        isGuest: this.isGuest,
        query: {
          ...query,
          source: 'snow_header',
        },
      };

      const buttonClass = [
        'tw-text-base lg:tw-text-sm',
        this.isGuest ? '' : 'lg:tw-my-2',
        'tw-w-full',
        'tw-whitespace-nowrap',
      ].join(' ');

      const text = this.isGuest ? this.registerButtonText : 'Buy Now';
      const to = getStartFreeTrialUrl(params);
      const type = ButtonTypes.allAccess;

      return {
        buttonClass,
        text,
        to,
        type,
      };
    },

    userLinkSets() {
      if (this.isGuest) {
        return [
          // Mobile
          [
            this.helpLink,
            this.logInLink,
            this.startFreeTrialButton,
          ],
          // Desktop
          [
            this.helpLink,
            this.logInLink,
            this.startFreeTrialButton,
          ],
        ];
      }

      if (!this.isAllAccess) {
        return [
          // Mobile
          [
            this.helpLink,
            this.settingsLink,
            this.logOutLink,
            this.startFreeTrialButton,
          ],
          // Desktop
          [
            this.helpLink,
            this.accountLink,
            this.startFreeTrialButton,
          ],
        ];
      }

      return [
        // Mobile
        [
          this.helpLink,
          this.settingsLink,
          this.logOutLink,
        ],
        // Desktop
        [
          this.helpLink,
          this.accountLink,
        ],
      ];
    },

    userLinksClass() {
      return `lg:tw-order-3 tw-mt-1 lg:tw-mt-0 tw-pt-1 lg:tw-pt-0 tw-border-t lg:tw-border-t-0 ${this.$style.userLinks}`;
    },
  },

  watch: {
    /**
     * Always try to collapse the Site Nav Bar when the user navigates to a different page so it
     * isn't awkwardly left open when a new page is displayed.
     */
    $route(to, from) {
      if (to?.path !== from?.path) {
        this.handleCollapseNav();
      }
    },
  },

  methods: {
    ...mapActions(useUiStore, ['setShouldCollapseNav']),
    ...mapActions(useLoginStore, ['makeLogoutRequest']),

    getLinkComponent({ href }) {
      return href ? 'a' : resolveComponent('NuxtLink');
    },

    getLinkHref(link) {
      return link.href ? link.href : link.to;
    },

    getNavItemAnchorActiveClass(pageLink) {
      const path = String(pageLink.to);
      return this.$route.path.startsWith(path) ? 'tw-text-white' : null;
    },

    getReturnToQuery() {
      /* eslint camelcase: off */
      const return_to = this.$route?.query?.return_to || this.$route?.fullPath;

      if (typeof return_to !== 'string') {
        return {};
      }

      if (return_to.includes('/user/login')
        || return_to.includes('/user/register')
        || return_to === '/') {
        return {};
      }

      return { return_to };
    },

    handleAccountDropdownClose() {
      this.showAccountDropdown = false;
    },

    handleCollapseNav() {
      if (isScreenLg()) {
        // Don't collapse the navbar on large screens because it's not open!
        return;
      }

      window.setTimeout(() => {
        this.setShouldCollapseNav(true);
      }, 250);
    },

    handleDropdownLinkClick(event, dropdownLink) {
      this.showAccountDropdown = false;

      if (dropdownLink.onClick) {
        dropdownLink.onClick(event);
      }
    },

    async handleLogout(event) {
      event.preventDefault();
      event.stopPropagation();
      await this.makeLogoutRequest();
      window.location.href = '/user/logout';
    },

    handleUserLinkClick(event, userLink) {
      if (userLink.onClick) {
        userLink.onClick(event);
      }
      else {
        this.handleCollapseNav();
      }
    },

    toggleAccountDropdown(event) {
      event.preventDefault();
      event.stopPropagation();
      this.showAccountDropdown = !this.showAccountDropdown;
    },
  },
};
</script>

<style module>
.allAccessBadge {
  margin-left: -1.3rem;
  max-width: 0;
  opacity: 0;
  transition:
    all
    var(--auth-content-transition-duration)
    var(--auth-content-transition-function)
    var(--auth-content-transition-duration);
}

/*
 * TODO: Currently hiding the all access badge on mobile like the current site. This could probably
 * be implemented better in the future so that the hamburger doesn't wrap.
 */
@media (min-width: 992px) {
  .allAccessBadge.showAllAccess {
    margin-left: 0.35rem;
    max-width: 4.5rem;
    opacity: 1;
  }
}

.userLinks {
  border-color: var(--user-links-border-color);
}

@media (min-width: 992px) {
  .userLinks {
    transition:
      width,
      opacity
      var(--auth-content-transition-duration)
      var(--auth-content-transition-function);
  }

  .userLinks.hideAuthContent {
    opacity: 0;
    width: 13.125rem;
  }
}

.accountDropdownFreeUser {
  left: -26rem;
}

.accountDropdownListItem {
  border-color: var(--user-links-border-color);
}

.container :global(.nuxt-link-active) {
  color: white;
}
</style>
